.menuWrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: white;
  text-align: left;
  transition: transform 0.3s ease;
  transform: translateX(100%);
  overflow: auto;
  z-index: 1000;
}

.menuWrapperInner {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.menuWrapper.open {
  transform: translateX(0);
}

.menuWrapper a {
  display: flex;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 1.5rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  font-family: "RobotoRegular", sans-serif;
  color: #0d0c1d;
  text-decoration: none;
  transition: color 0.3s linear;
  text-align: start;
}

.menuWrapper a:first-of-type {
  margin-top: 2rem;
}

.menuWrapper a:hover {
  color: #343078;
}

.menuWrapper p {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5rem;
  font-family: "RobotoRegular", sans-serif;
  color: #919191;
  text-decoration: none;
  transition: color 0.3s linear;
  margin: 64px 0 0 0;
}

.burgerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.burgerWrapperClose {
  position: absolute;
  top: 42px;
  right: 2rem;
}

.burgerWrapper:focus {
  outline: none;
}


.burgerWrapper div {
  width: 2rem;
  height: 0.25rem;
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.burgerWrapper.open div {
  background-color: #000;
}

.burgerWrapper div:first-child {
  transform: rotate(0);
}

.burgerWrapper.open div:first-child {
  transform: rotate(45deg);
}

.burgerWrapper div:nth-child(2) {
  opacity: 1;
  transform: translateX(0);
}

.burgerWrapper.open div:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}

.burgerWrapper div:nth-child(3) {
  transform: rotate(0);
}

.burgerWrapper.open div:nth-child(3) {
  transform: rotate(-45deg);
}

@media (max-width: 576px) {
  .menuWrapper {
    width: 100%;
  }

  .menuWrapper p {
    font-size: 1rem;
    text-align: center;
  }

  .menuWrapper a {
    font-size: 1.5rem;
    text-align: center;
    justify-content: center;
  }
}

.menuWrapper a.disabledItem {
  position: relative;
  color: #bbb;
}
.menuWrapper a.disabledItem::after {
  position: absolute;
  bottom: 65%;
  content: 'Wen? Soon ™';
  font-size: 50%;
  text-decoration: none
;
}
