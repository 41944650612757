/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@font-face {
  font-family: "RobotoMono";
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/RobotoMono/RobotoMono-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "RobotoMonBold";
  font-weight: 800;
  font-display: swap;
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "RobotoRegular";
  font-display: swap;
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "RobotoMonoMedium";
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/RobotoMono/RobotoMono-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "RobotoMonoSemiBold";
  font-weight: 800;
  font-display: swap;
  src: url("./fonts/RobotoMono/RobotoMono-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "RobotoMonoBold";
  font-weight: 800;
  font-display: swap;
  src: url("./fonts/RobotoMono/RobotoMono-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "AkiraExpanded";
  font-weight: 800;
  font-display: swap;
  src: url("./fonts/AkiraExpanded/AkiraExpanded.otf") format("opentype");
}

/* @layer utilities {
  .word-md {
    word-spacing: 4px;
  }

  .min-h-100 {
    min-height: 100%;
  }

  .mono-bold {
    font-family: "RobotoMonoBold", monospace;
    font-weight: 800;
  }

  .mono-reg {
    font-family: "RobotoMono", monospace;
    font-weight: 400;
  }
} */

html {
  height: 100%;
  /*overflow-x: hidden;*/
}

body {
  background: #1C1C1C !important;
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

@media (max-width: 992px) {
  body {
    overflow-x: hidden;
    width: 100vw;
  }
}

/*#root {*/
/*  overflow: hidden;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h3 {
  font-family: "RobotoMono", monospace;
}

h2 {
  font-family: "RobotoMonoMedium", monospace;
}

h1 {
  font-family: "RobotoRegular", sans-serif;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: underline;
}

input:focus {
  outline: 0;
}

ul {
  list-style: disc;
}

/* .number {
  @apply text-white text-xxl m-0 mobile: w-full mobile:text-center font-normal;
}

.text1 {
  @apply text-sm mb-xl mobile: text-s mobile:w-full mobile:text-center font-normal;
}

.text2 {
  @apply text-s font-normal line-clamp-3 mb-4;
}

.title1 {
  @apply leading-extra-loose text-xl font-bold mb-lg mobile: text-md mobile:leading-40 mobile:text-center;
}

.title2 {
  @apply text-md leading-48 font-extrabold mb-lg;
} */